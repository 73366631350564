<template>
	<div class="orderDetailpage" v-loading="detailoading">
		<div class="orderInformation">
			<div class="mainCon">
				<el-row class="line">
					<el-col :span="12">
						<span style="margin:0 10px">订单编号:
							<span style="color:#F56C6C;" v-if="orderDetail.SendType==1">[到店自提]</span>
							<span style="color:#F56C6C;" v-else>[快递发货]</span>
							{{orderDetail.OrderNo}}
            </span>
						<el-tooltip :content="'微信交易单号：'+orderDetail.PayNo" placement="bottom" effect="light" v-if="orderDetail.PayNo">
							<span style="color:#409EFF;cursor: pointer;">更多</span>
						</el-tooltip>
					</el-col>
					<el-col :span="5">订单状态：{{orderDetail.OrderStateDisplay}}</el-col>
					<el-col :span="7">

						<buttonPermissions :datas="'addStartDisOrderBtn'">

							<el-popover placement="top-start" trigger="click" style="margin: 0 5px;min-width:160px;" >
								<div style="display: flex;align-items: center;align-items: center;">
									<el-button type="text" style="margin-right:5px;" @click="orderDetail.Star=0,rateChange()" v-if='orderDetail.Star'>清空</el-button>
									<el-rate v-model="orderDetail.Star"  @change="rateChange"></el-rate>
								</div>

								<el-button slot="reference" type="text" class="pointers" @click="addStar" style="padding: 0">
									<div v-show='!orderDetail.Star'>加星</div>
									<div v-show='orderDetail.Star'  style="display: flex;align-items: center;align-items: center;">
										<span>{{orderDetail.Star}}</span>
										<el-rate style="display: inline-block;vertical-align: middle;" v-model="startCount" :max="1"></el-rate>
									</div>
								</el-button>
							</el-popover>
							
						</buttonPermissions>
					</el-col>
				</el-row>
				 <el-alert style="word-wrap:break-word;width:95%;" 
					type="warning" :closable="false" v-if="orderDetail.CustomerRemark">
					<div style="display:flex" slot="title">
						买家留言：
						<div style="display:flex;flex-direction:column">
							<div v-for="(v,i) in CustomerRemark" :key="i">{{v}}</div>
						</div>
					</div>
				</el-alert>
				<!-- 待付款 -->
				<el-alert style="word-wrap:break-word;width:95%;" :title="'系统备注:'+ orderDetail.SystemRemark" type="success"
				 :closable="false" v-if="orderDetail.SystemRemark"></el-alert>

				<el-button style="margin:20px 0" @click="ordermarkDialogVisible=true">备注</el-button>
			</div>

			<div class="mainCon" v-if="orderDetail.ExpressList&&orderDetail.ExpressList.length">
				<el-tabs v-model="activeTabName" type="card" @tab-click="handleChangeTab">
					<el-tab-pane :key="index" v-for="(item, index) in orderDetail.ExpressList"  :label="'包裹'+(index+1)" :name="item.name">
						<div class="pack-tabs">
							<div class="left-content">
									<div>
                    物流公司：{{item.ExpressValue}}
                    <buttonPermissions :datas="'modifyDistributeOrderSend'" v-if='orderDetail.OrderState!=5'>
                      <el-button type="text" style="margin-left: 5px;" @click='handleModifySend(item.ExpressValue,item.ExpressNo,item.DetailIdList)'>修改物流</el-button>
                    </buttonPermissions>
                  </div>
									<div>物流单号：{{item.ExpressNo}}</div>
									<div>发货时间：{{item.SendTime}}</div>
									<div class="imgs-content" :style="{width: (widthUp1366 ? '725px' : '420px')}">
										<i class="el-icon-arrow-left icon-left" @click="handleLeft" v-show="bages.current>1"></i>
										<i class="el-icon-arrow-left icon-unleft"  v-show="bages.current==1"></i>

										<div class="imgs-list"  :style="{width: (widthUp1366 ? '675px' : '375px')}">
											<ul :style="{transform:`translateX(-${(bages.current-1)*(bages.size)*75}px)`}">
												<li class="imgs-item" v-for="(tt,index) in item.ProductList" :key="index">
													<img :src="tt.ImgUrlComplete+'@!cut64'"> 
													<div>{{tt.ProductName}}</div>
												</li>
											</ul>
										</div>
										
										<i class="el-icon-arrow-right icon-right" @click="handleRight" v-show="bages.rest>0"></i>
										<i class="el-icon-arrow-right icon-unright"  v-show="bages.rest<=0"></i>
									</div>
							</div>
							<div class="right-content">
								<p>
									<span style="color:#F56C56">*</span>
									<span>物流跟踪：</span>
								</p>
								<el-timeline style="height:180px;overflow:auto" v-if="item.ExpressTraceList.length">
									<el-timeline-item v-for="(j,index) in item.ExpressTraceList" :key="index" :timestamp="j.AcceptTime" placement="top">
										<el-card style="padding:0 5px">
											<p style="margin-top:5px;word-wrap:break-word;">{{j.AcceptStation}}</p>
										</el-card>
									</el-timeline-item>
								</el-timeline>

								<div v-else style="text-align: center;line-height:180px;">暂未跟踪到物流信息</div>

							</div>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>

			<!-- 一些信息 -->
			<div class="mainCon">
				<el-row class="line">
					<el-col :span="6">
						<!-- 已完成 -->
						<div style="margin-bottom: 15px;">
							<span style="font-weight: bold;">配送信息</span>
							<!-- 已完成才有 -->
							<!-- <span style="color:blue;margin-left:10px;cursor: pointer;" @click="lookLogest" v-if="orderDetail.SendType==0&&(orderDetail.OrderState==4||orderDetail.OrderState==5)">查看物流</span> -->
						</div>
						<div v-if="orderDetail.SendType==0">配送方式：快递发货</div>
						<div v-else>配送方式：到店自提</div>
						<div v-if='orderDetail.ShopName'>支付业绩归属门店：{{orderDetail.ShopName}}</div>
						<!-- <div>下单门店：{{orderDetail.ShopName}}</div> -->
						<div v-if="orderDetail.OrderState ==4 || orderDetail.OrderState ==5">
							<!-- 完成后才有的 -->
							<!-- <div v-if="orderDetail.SendType==0">物流公司：{{orderDetail.ExpressValue}}</div> -->
							<!-- <div v-if="orderDetail.SendType==0">物流单号：{{orderDetail.ExpressNo}}</div> -->
							<!-- 已发货才有的 -->
							<div v-if="orderDetail.SendType==1">提货门店：{{orderDetail.PickUpShopName}}</div>
							<div v-if="orderDetail.SendType==1">提货时间：{{orderDetail.SendTime}}</div>
						</div>
					</el-col>
					<el-col :span="6">
						<div style="margin-bottom: 15px;">
							<span style="font-weight: bold;margin-bottom: 15px;" v-if="orderDetail.SendType==0">收货信息</span>
							<span style="font-weight: bold;margin-bottom: 15px;" v-else>提货信息</span>
							<buttonPermissions :datas="'edit'">
								<span style="color:blue;margin-left:10px;cursor: pointer;" @click="editAdress" v-if="orderDetail.OrderState==2 || orderDetail.OrderState==3">修改</span>
							</buttonPermissions>
						</div>
						<div v-if="orderDetail.SendType==0">
							<div>收货人：{{orderDetail.ReceiveName}}</div>
							<div class="flexRow">收货手机号：{{orderDetail.ReceivePhone}}
								<eyes-hide v-model="orderDetail.ReceivePhone" :data="{OrderNo:orderDetail.OrderNo}" range="order"></eyes-hide>
							</div>
							<div class="shipping-address">收货地址：{{orderDetail.ReceiveProvince}}{{orderDetail.ReceiveCity}}{{orderDetail.ReceiveArea}}{{orderDetail.ReceiveAddress}}</div>
						</div>
						<div v-else>
							<div>提货人：{{orderDetail.ReceiveName}}</div>
							<div>提货手机号：{{orderDetail.ReceivePhone}}</div>
							<div class="shipping-address">提货地址：{{orderDetail.SelectShopProvince}}{{orderDetail.SelectShopCity}}{{orderDetail.SelectShopArea}}{{orderDetail.SelectShopAddress}}</div>
						</div>
            <div class="flexRow" v-if="orderDetail.RealName">实名信息：
              <span>{{orderDetail.RealName}}</span>
              <span v-if="orderDetail.IdCardNo">（{{orderDetail.IdCardNo}}）</span>
							<eyes-hide v-model="orderDetail.IdCardNo" :data="{MemberId:orderDetail.CustomerId}" type="IdCard"></eyes-hide>
            </div>
					</el-col>
					<el-col :span="6">
						<div style="font-weight: bold;margin-bottom: 15px;">分销商</div>
						<div>分销商：{{orderDetail.MallName}}</div>
					</el-col>
					<!-- <el-col :span="6">
						<div style="font-weight: bold;margin-bottom: 15px;">买家信息</div>
						<div>买家信息：{{orderDetail.CustomerNameDisplay}}</div>
						<div v-if="orderDetail.CustomerVipRoleName">会员级别：{{orderDetail.CustomerVipRoleName}}</div>
						<div v-if="!orderDetail.CustomerVipRoleName">会员级别：--</div>
						<div v-if="orderDetail.CustomerDistributorRoleName">推手：{{orderDetail.CustomerDistributorRoleName}}</div>
						<div v-if="!orderDetail.CustomerDistributorRoleName">推手：--</div>
					</el-col>
					<el-col :span="6">
						<div>
							<div style="font-weight: bold;margin-bottom: 15px;">收益与提成</div>
							<div>锁粉店员：{{orderDetail.EmployeeName}}
								<span v-if="orderDetail.EmployeePhone">({{orderDetail.EmployeePhone}})</span>
							</div>
							<div v-if="orderDetail.EmployeeAwardMoney">店员提成：{{orderDetail.EmployeeAwardMoney }}</div>
							<div v-if="!orderDetail.EmployeeAwardMoney">店员提成：--</div>
							<div>推手：{{orderDetail.MemberName}}
								<span v-if="orderDetail.MemberPhone">({{orderDetail.MemberPhone}})</span>
							</div>
							<div v-if="orderDetail.MemberAwardMoney">自营零售收益：{{orderDetail.MemberAwardMoney}}</div>
							<div v-if="!orderDetail.MemberAwardMoney">自营零售收益：--</div>
							<div v-if="orderDetail.MemberRecommendAwardMoney">直推销售收益：{{orderDetail.MemberRecommendAwardMoney}}</div>
							<div v-if="!orderDetail.MemberRecommendAwardMoney">直推销售收益：--</div>
						</div>
					</el-col> -->
				</el-row>
			</div>

			<!-- 商品表格 -->
			<div class="mainCon">
				<el-table :data="productsData" style="width:100%;" default-expand-all :row-class-name="setClassName">
					<el-table-column type="expand">
						<template slot-scope="scope">
							<div style="overflow:hidden;" v-if="scope.row.MixList">
								<!--  -->
								<div class="reply-expand" :key="index" v-for="(item ,index) in scope.row.MixList">
									<div class="product-info">
										<img v-if="item.ImgUrlComplete" :src="item.ImgUrlComplete">
										<svg-icon v-else icon-class="noImgIcon" />
										<div style="min-height: auto;">
											<div style="display:flex;justify-content:space-between;align-items:flex-start;">
												<div style="width:280px;">
													<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{item.ProductName}}</pre>
												</div>
											</div>
											<div>{{item.ProductNo}}</div>
										</div>
									</div>
									<div class="product-spec">
										<div style="padding-bottom:10px;">
											<span v-if="item.SpecValue || item.SpecValue2">
												<span v-if="item.SpecValue">{{item.SpecValue}}</span>
												<span v-if="item.SpecValue2">,{{item.SpecValue2}}</span>
											</span>
											<span v-else>默认规格</span>
										</div>
										{{item.Barcode}}
									</div>
									<div class="product-msg">
										<div>{{item.UnitPriceDisplay}}</div>
									</div>
									<div class="product-msg">
										<div>0</div>
									</div>
									<div class="product-msg">
										<div>{{item.ProductCount}}</div>
									</div>
									<div class="product-msg">
										<div>{{(item.UnitPriceDisplay*item.ProductCount).toFixed(2)}}</div>
									</div>
									<div class="product-msg">
										<div>0</div>
									</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="date" label="商品" width="400">
						<template slot-scope="scope">
							<div class="product-info" v-if="scope.row.ProductType==1">
								<img v-if="scope.row.ImgUrl" :src="imgUrl+scope.row.ImgUrl">
								<svg-icon v-else icon-class="noImgIcon" />
								<div style="min-height: auto;">
									<div style="display:flex;justify-content:space-between;align-items:flex-start;">
										<div style="color: #f00;font-size: 12px;width:40px" v-if="scope.row.ProductType==1">[组合]</div>
										<div style="color: #f00;font-size: 12px;width:40px" v-if="scope.row.DealType==3">[赠品]</div>
										<div style="width:280px;">
											<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.ProductName}}</pre>
										</div>
									</div>
									<div>{{scope.row.ProductNo}}</div>
								</div>
							</div>

							<div class="product-info" v-if="scope.row.ProductType!=1">
								<img v-if="scope.row.ImgUrl" :src="imgUrl+scope.row.ImgUrl">
								<svg-icon v-else icon-class="noImgIcon" />
								<div>

									<div style="display:flex;justify-content:space-between;align-items:center;">
										<div style="color: #f00;font-size: 12px;width:40px" v-if="scope.row.DealType==3">[赠品]</div>
										<div>{{scope.row.ProductName}}</div>
									</div>
									<div>{{scope.row.ProductNo}}</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="name" label="规格" width="150">
						<template slot-scope="scope">
							<span v-if="scope.row.SpecValue || scope.row.SpecValue2">
								<span v-if="scope.row.SpecValue">{{scope.row.SpecValue}}</span>
								<span v-if="scope.row.SpecValue2">,{{scope.row.SpecValue2}}</span>
							</span>
							<span v-else>默认规格</span>
							<div>{{scope.row.BarCode }}</div>
						</template>
					</el-table-column>
					<el-table-column prop="address" label="商品价格" width="150">
						<template slot-scope="scope">
							<div>{{scope.row.ProductPrice}}</div>
							<div>
								<div v-if="scope.row.VipDiscount">会员折扣:{{scope.row.VipDiscount/10}}折</div>
								<div v-if="scope.row.ActivityDiscountRate">限时折扣:{{scope.row.ActivityDiscountRate/10}}折</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="ReduceMoney" label="优惠金额" width="150"></el-table-column>
					<el-table-column prop="ProductCount" label="购买数量" width="150"></el-table-column>
					<el-table-column prop="address" label="小计(元)" width="150">
						<template slot-scope="scope">
							<div>{{(scope.row.ProductPrice*scope.row.ProductCount+scope.row.ReduceMoney).toFixed(2)}}</div>
						</template>
					</el-table-column>
					<!-- <el-table-column prop="ProductCashbackMoney" label="返现退款" width="150"></el-table-column> -->
					<el-table-column label="发货状态" width="150">
						<template slot-scope="scope">
							<span v-if='scope.row.SendState == 0' style="color: #F56C56">未发货</span>
							<span v-if='scope.row.SendState == 1' style="color: #409EFF">已发货</span>
							<span v-if='scope.row.SendState == 2'>无需发货</span>
						</template>
					</el-table-column>
					<el-table-column prop="address" label="退款信息" min-width="150">
						<template slot-scope="scope" style="font-size: 12px;">
							<div v-if="orderDetail.OrderType!=1&&orderDetail.OrderState==1">买家未支付，无需主动退款</div>

							<div style="color:#409EFF" v-if="orderDetail.OrderType!=1&&scope.row.IsSupport">
								<div>退款中</div>
								<div style="color:#909399;font-size: 12px;">商品退款中，不可主动退款</div>
							</div>

							<div v-if="orderDetail.OrderType!=1&&orderDetail.OrderState==8" style="color:#909399;font-size: 12px;">
								订单已关闭,不可继续退款
							</div>

							<div v-if="scope.row.DealType==3 ||orderDetail.OrderType==1" style="color:#909399;font-size: 12px;">
								赠品不支持退款
							</div>

							<div v-if="orderDetail.OrderType==5" style="color:#909399;font-size: 12px;">
								助力活动单不支持退款
							</div>

							<div v-if="scope.row.DealType!=3&&orderDetail.OrderType!=1&&orderDetail.OrderState!=1&&orderDetail.OrderState!=8&&(!scope.row.IsSupport)&&orderDetail.OrderType!=5">
								<div v-if="scope.row.RefundCount!=scope.row.ProductCount">
									<div v-if="scope.row.RefundMoney">已退款￥{{scope.row.RefundMoney}}</div>
									<span class="self-refuse" style="margin-right:5px;" @click="refuseMoeny(scope.row,false)">主动退款</span>
									
									<buttonPermissions :datas="'distributeVirtualRefund'" v-if="orderDetail.PayBy==5||orderDetail.PayBy==9">
										<span class="self-refuse" @click="refuseMoeny(scope.row,true)">虚拟退款</span>
									</buttonPermissions>
									
								</div>
								<div style="color:#909399" v-if="scope.row.RefundCount==scope.row.ProductCount">商品可退数量为0，不可继续退款</div>
							</div>


						</template>
					</el-table-column>
				</el-table>

				<div class="comeback-money">
					<p>商品合计:{{productAll}}</p>
					<p>优惠合计:{{discoutAll}}</p>
					<!-- <span style="color:rgb(64, 158, 255);cursor: pointer;display: inline-block;width:80px;text-align: right;" @click="lookBelifDetails">优惠明细</span> -->
					<p>运费:{{orderDetail.FreightMoney}}</p>
					<p style="color:rgb(64, 158, 255)" v-if="ChangeFreightMoney">（运费改价：运费
						<span v-if="ChangeFreightMoney>0">加</span>
						<span v-if="ChangeFreightMoney<0">减</span>
						{{ChangeFreightMoney}}）
					</p>
					<p class="allMoney">
            <span>订单合计: </span>
            <span style="color:#F56C6C ">{{orderAll}}</span>
            <div>{{orderDetail.PayByDisplay}}</div>
          </p>
					<!-- <p>返现退款: <span style="color:#F56C6C ">{{moneyAll}}</span></p> -->
				</div>
			</div>

			<!-- 订单轨迹 -->
			<div class="refund-record">
				<div class="title">订单轨迹</div>
				<div class="refund-timeline" v-if="orderDetail.RecordList && orderDetail.RecordList.length">
					<el-timeline>
						<el-timeline-item v-for="(j,index) in orderDetail.RecordList " :key="index" v-if="j&&j.AddTime" :timestamp="j.AddTime"
						 placement="top">
							<el-card style="padding:0 20px">
								<h4 v-if="j.OperatorType==1">商家</h4>
								<h4 v-if="j.OperatorType==2">买家</h4>
								<h4 v-if="j.OperatorType==3">系统</h4>
								<p style="margin-top:5px;word-wrap:break-word;">{{j.Remark}}</p>
							</el-card>
						</el-timeline-item>
					</el-timeline>
				</div>
			</div>

			<!-- 主动退款的弹框 -->
			<el-dialog title="主动退款" :visible.sync="returnselfVisivble" width="850px" v-loading="loading">
				<div style="color:#fda23c;margin-bottom:10px;display:flex;align-items: center;"  v-if="isVirtualRefund" >
					<i class="el-icon-warning" style="font-size: 24px;margin-right:5px;"></i>
					<span >注意:您当前正在使用虚拟退款功能，此功能无法通过系统退款至买家支付账户，请通过线下转账完成款项退回</span>
				</div>
				<el-table :data="refuseTable" style="width: 100%">
					<el-table-column prop="date" label="商品" width="400">
						<template slot-scope="scope">
							<div class="product-info" @click="lookCounpGoods(scope.row)" v-if="scope.row.ProductType==1">
								<img v-if="scope.row.ImgUrl" :src="imgUrl+scope.row.ImgUrl">
								<svg-icon v-else icon-class="noImgIcon" />

								<div style="min-height: auto;">
									<div style="display:flex;justify-content:space-between;align-items:flex-start;">
										<div style="color: #f00;font-size: 12px;width:40px" v-if="scope.row.ProductType==1">[组合]</div>

										<div style="width:280px;">
											<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.ProductName}}</pre>
										</div>
									</div>
									<div>{{scope.row.ProductNo}}</div>
								</div>
							</div>

							<div class="product-info" v-else>
								<img v-if="scope.row.ImgUrl" :src="imgUrl+scope.row.ImgUrl">
								<svg-icon v-else icon-class="noImgIcon" />
								<div>
									<div style="display:flex;justify-content:space-between;align-items:center;">
										<div>{{scope.row.ProductName}}</div>
										<!-- <i v-if="scope.row.ProductType==0" class="el-icon-edit-outline" @click="productEdit(scope.row)"></i> -->
									</div>
									<div>{{scope.row.ProductNo}}</div>
								</div>
							</div>


						</template>

					</el-table-column>
					<el-table-column prop="name" label="规格">
						<template slot-scope="scope">
							<span v-if="scope.row.SpecValue">{{scope.row.SpecValue}}</span>
							<span v-if="scope.row.SpecValue2">,{{scope.row.SpecValue2}}</span>
							<span v-if="!scope.row.SpecValue&&(!scope.row.SpecValue2)">默认规格</span>
							<div>{{scope.row.BarCode}}</div>
						</template>
					</el-table-column>

					<el-table-column prop="RemainCount" label="可退数量">
					</el-table-column>
					<el-table-column prop="name" label="退款数量" width="110">
						<template slot-scope="scope">
							<input v-model="scope.row.RefundCount" @input="refundMoneysChange" style="padding:0 10px;width:80px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
							 oninput="this.value=this.value.replace(/\D/g,'').replace(/^0+(?=\d)/,'')" type="number">
							</input>
						</template>
					</el-table-column>
					<el-table-column prop="name" label="退款金额">
						<template slot-scope="scope">
							<div>￥{{refuseMoneys}}</div>
							<div style="color:rgb(144, 147, 153);font-size: 12px;" v-if="scope.row.IsRefundFreight&&(scope.row.RemainCount==scope.row.RefundCount ||(scope.row.RemainMoney==0&&Number(scope.row.RefundCount)>0))">(含运费{{scope.row.FreightMoney}}元)</div>
							<div style="color:rgb(144, 147, 153);font-size: 12px;" v-else>(含运费0元)</div>
						</template>
					</el-table-column>
				</el-table>

				<div style="text-align: center;margin-top: 20px;">
					<el-button @click="returnselfVisivble=false" style="width:120px;margin-right:15px">关闭</el-button>
					<el-button type="primary" @click="sureRefuse" style="width:120px" :loading="loading">确认退款</el-button>
				</div>

			</el-dialog>

			<!-- 订单未发货 点击退款 -->
			<el-dialog :visible.sync="nosendVisivble" width="500px">

				<div class="sendOrders">该订单为礼包授权单，退款成功后，将自动取消授权；
					若订单已经提交给仓库进行发货，主动退款前，请告知相关发货人员，避免商品多发。是否确认继续退款？</div>
				<div style="text-align: center;margin-top: 20px;">
					<el-button @click="nosendVisivble=false" style="width:120px;margin-right:15px">关闭</el-button>
					<el-button type="primary" @click="returnselfVisivble=true;nosendVisivble=false" style="width:120px" :loading="loading">继续退款</el-button>
				</div>
			</el-dialog>
			<!-- //skds -->

			<!-- 订单已发货或 已完成 -->
			<el-dialog :visible.sync="successVisivble" width="400px">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
					<div class="sendOrders">该订单为礼包授权单，退款成功后，请确认是否需要同时取消授权？</div>
					<el-form-item prop="resource">
						<el-radio-group v-model="ruleForm.resource">
							<el-radio :label="1">取消授权</el-radio>
							<el-radio :label="0">不取消授权</el-radio>
						</el-radio-group>
					</el-form-item>

					<div style="text-align: center;margin-top: 20px;">
						<el-button @click="successVisivble=false" style="width:120px;margin-right:15px">关闭</el-button>
						<el-button type="primary" @click="submitForm('ruleForm')" style="width:120px" :loading="loading">继续退款</el-button>
					</div>
				</el-form>
			</el-dialog>

			<!-- 已审单点击退款 -->
			<el-dialog :visible.sync="checkVisivble" width="500px">
				<div class="sendOrders">若订单已经提交给仓库进行发货，主动退款前，请告知相关发货人员，避免商品多发。</div>
				<div style="text-align: center;margin-top: 20px;">
					<el-button @click="checkVisivble=false" style="width:120px;margin-right:15px">关闭</el-button>
					<el-button type="primary" @click="checkVisivble=false;returnselfVisivble=true" style="width:120px" :loading="loading">继续退款</el-button>
				</div>
			</el-dialog>

			<!-- //修改收货地址的弹窗 -->
			<!-- :before-close="modelClose" -->
			<el-dialog :visible.sync="editAddressModel" title="修改收货信息" width="50%" v-loading="loading">
				<el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm" :rules="rules">
					<el-form-item label="收货人姓名" prop="Name">
						<el-input placeholder="请输入内容" v-model="ruleForm.Name" clearable style='width:300px;'></el-input>
					</el-form-item>
					<el-form-item label="收货人手机" prop="phone">
						<el-input placeholder="请输入手机号" v-model="ruleForm.phone" style="width: 300px;" clearable maxlength="11" onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
						 onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}">
						</el-input>
					</el-form-item>
					<el-form-item label="收货地址" prop="AddressArr">
						<el-cascader style="width: 300px;" :props="props" :options="dataList" clearable @change="handleAddressChange"
						 v-model="ruleForm.AddressArr" />
					</el-form-item>

					<el-form-item style="box-sizing: border-box" prop="Address">
						<el-col :span="14">
							<el-input type="textarea" v-model="ruleForm.Address" rows="4" placeholder="请输入详细地址"></el-input>
						</el-col>
					</el-form-item>
				</el-form>
				<div style="text-align: center;margin-top: 10px;">
					<el-button @click="modelClose" style="width:120px;">关闭</el-button>
					<el-button type="primary" @click="saveAddressinfo('ruleForm')" style="width:120px;">确认修改</el-button>

				</div>

			</el-dialog>

			<!-- 到店自提，不可换地址 -->
			<el-dialog :visible.sync="noeditesVisivble" width="350px">

				<div class="sendOrders" style="text-align: center;">到店自提订单不可修改提货信息</div>
				<div style="text-align: center;margin-top: 20px;">
					<el-button @click="noeditesVisivble=false" style="width:100px;margin-right:15px">关闭</el-button>
				</div>
			</el-dialog>

			<!-- 优惠明细弹框 -->
			<el-dialog title="订单优惠明细" :visible.sync="orderdetailsVisivble" width="850px">
				<el-table :data="ordermenyData" style="width: 100%">
					<el-table-column prop="ActivityTypeDisplay" label="活动类型"></el-table-column>
					<el-table-column prop="ActivityName" label="活动名称"></el-table-column>
					<el-table-column prop="ReduceMoney" label="优惠金额"></el-table-column>
				</el-table>

			</el-dialog>

			<!-- 组合商品查看子商品 -->
			<el-dialog title="组合商品明细" :visible.sync="contactVisivble" width="850px">
				<el-table :data="contactData" style="width: 100%">
					<el-table-column prop="date" label="商品名称" width="250">
						<template slot-scope="scope">
							<div class="product-info">
								<img v-if="scope.row.ImgUrl" :src="imgUrl+scope.row.ImgUrl">
								<svg-icon v-else icon-class="noImgIcon" />
								<div>{{scope.row.ProductName}}</div>
							</div>
						</template>

					</el-table-column>
					<el-table-column prop="date" label="规格">
						<template slot-scope="scope">
							<div v-if="scope.row.SpecValue||scope.row.SpecValue2">
								{{scope.row.SpecValue}}
								<span v-if="scope.row.SpecValue2">,</span>
								{{scope.row.SpecValue2}}
							</div>
							<div v-else>默认规格</div>
						</template>

					</el-table-column>

					<el-table-column prop="ProductCount" label="组合内数量"></el-table-column>
					<el-table-column prop="UnitPriceDisplay" label="组合内售价"></el-table-column>
				</el-table>

			</el-dialog>
			

			<!-- 订单备注弹框 -->
			<el-dialog title="订单备注" :visible.sync="ordermarkDialogVisible" width="650px">
				<el-form :model="orderForm" ref="orderForm" :rules="rules">
					<el-form-item label="订单备注 :" label-width="120px">
						<el-input v-model="orderForm.orderMark" maxlength="200" type="textarea" style="width:300px;" clearable></el-input>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button @click="ordermarkDialogVisible=false">关 闭</el-button>
					<el-button type="primary" @click="sureOrdermark('orderForm')" :loading="loading">确 认</el-button>
				</span>
			</el-dialog>
      
      <!-- 快递 -->
      <el-dialog title="修改物流信息" :visible.sync="modifySendVisible" width="700px" class="send-dialog">
        <div class='old-send-mess'>
          <span>原物流公司：{{this.sendForm.oldExpressValue}}</span>
          <br>
          <span>原物流单号：{{this.sendForm.oldExpressNo}}</span>
        </div>
        <el-form :model="sendForm" ref="sendForm" :rules="sendRules" v-loading='sendLoading'>
          <el-form-item label="物流公司 :" prop="ExpressId">
            <el-select v-model="sendForm.ExpressId" placeholder="请选择物流公司" style="width:300px;">
              <el-option v-for="(i,index) in  ExpressList" :key="index" :label="i.ExpressName" :value="i.Id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="物流单号 :" prop="ExpressNo"  >
            <el-input v-model="sendForm.ExpressNo" placeholder="请输入物流单号" style="width:300px;"></el-input>
          </el-form-item>
        </el-form>
        <div style="text-align: center;">
          <el-button @click="modifySendVisible= false" style="width:120px">取 消</el-button>
          <el-button type="primary" @click="submitModifySends('sendForm')" style="width:120px">确认修改</el-button>
        </div>
      </el-dialog>


		</div>

	</div>
</template>

<script>
	import {
		detailcomboProduct,
		detailcomboDetail,
		orderIndexinit,
		orderEditExpress,
		supplierOrderEditExpress
	} from '@/api/goods'
	import {
		orderlookDetail,
		orderIndexStar,
		orderlookExpress,
		ordereditReceive,
		orderRemark,
		ordererefundInit,
		ordererefundsave
	} from '@/api/TurnTomySelf'
	import axios from 'axios'
	import config from '@/config/index'

	import eyesHide from "@/components/eyesHide"
	import buttonPermissions from '@/components/buttonPermissions';
	export default {
		components: {
			buttonPermissions,
			eyesHide
		},
		data() {
			var checkPhone = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('手机号不能为空'));
				} else {
					const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
					console.log(reg.test(value));
					if (reg.test(value)) {
						callback();
					} else {
						return callback(new Error('请输入正确的手机号'));
					}
				}
			};


			return {
				isVirtualRefund: false,
				startCount: 1,
				detailoading: false,
				refuseMoneys: 0,
				imgUrl: config.IMG_BASE,
				imgApi: config.UPLOAD_IMG,
				productAll: 0,
				discoutAll: 0,
				moneyAll: 0,
				orderAll: 0,
				ordermarkDialogVisible: false,
				orderForm: {
					orderMark: ''
				},
				props: {
					label: 'name',
					value: 'id',
					children: 'child'
				},
				dataList: [],
				expressInfo: {},
				logistdialogVisible: false,
				orderDetail: {},
				startNum: null,
				loading: false,
				productsData: [{
					address: ''
				}],
				returnselfVisivble: false,
				refuseTable: [],
				nosendVisivble: false,
				successVisivble: false,
				ruleForm: {
					resource: 0,
					OrderNo: '',
					Name: '',
					phone: '',
					Province: '',
					City: '',
					Area: '',
					Address: '',
					AddressArr: [],
				},
				rules: {
					resource: [{
						required: true,
						message: '请选择是否取消授权',
						trigger: 'change'
					}],
					Name: [{
							required: true,
							message: '请输入收货人姓名',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 15,
							message: '长度在 1 到 15 个字符',
							trigger: 'blur'
						}
					],
					phone: [{
						validator: checkPhone,
						trigger: 'blur'
					}],
					AddressArr: [{
						required: true,
						message: '请选择收货地址',
						trigger: 'change'
					}],
					Address: [{
							required: true,
							message: '请输入详细地址',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 100,
							message: '长度在 1 到 100 个字符',
							trigger: 'blur'
						}
					],
					orderMark: [{
							required: true,
							message: '请填写订单备注',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 200,
							message: '长度在 1 到 200 个字符',
							trigger: 'blur'
						}
					],

				},
				checkVisivble: false,
				editAddressModel: false,
				ProvinceList: [],
				CityList: [],
				AreaList: [],
				noeditesVisivble: false,
				orderdetailsVisivble: false,
				ordermenyData: [],
				contactVisivble: false,
				contactData: [],
				OrderNo: null,
				RecordList: [],
				OrderDetailId: null,
				ChangeFreightMoney: 0,
				activeTabName: 'tab1',
				imgsList: [],
        marginIndex: 0,
				marginIndexRight: false,
				bages: {
					current: 1,
					size: 5,
					rest: 0
				},
				widthUp1366: false,
        modifySendVisible: false,
        sendLoading: false,
        ExpressList: [],
				sendForm: {
					ExpressId: '',
          oldExpressValue: '',
          oldExpressNo: '',
					selectedList: [],
          ExpressNo: '',
          DetailId: null,
				},
				sendRules: {
					ExpressId: [{
						required: true,
						message: '请选择物流公司',
						trigger: 'change'
					}],
					ExpressNo: [{
						required: true,
						message: '请输入物流单号',
						trigger: 'blur'
					}]
				},
				CustomerRemark:[],
			}
		},
		mounted() {
			this.initScreenWidth()
		},
		beforeMount() {
			this.getDataList()
			this.OrderNo = this.$route.query.Id;
			this.getOrderdata()
		},
		methods: {
      handleModifySend(oldExpressValue,oldExpressNo,DetailIdList){
        this.sendForm.ExpressId= ''
        this.sendForm.ExpressNo= ''
        this.sendForm.oldExpressValue= oldExpressValue
        this.sendForm.oldExpressNo= oldExpressNo
        this.sendForm.DetailIdList = DetailIdList
        this.getExpressList()
      },
			async getExpressList() {
        this.sendLoading = true
				try {				
					let result = await orderIndexinit()
					this.ExpressList = result.Result.ExpressList;
          if(result.IsSuccess){
            this.modifySendVisible = true
          }
				} catch (e) {
					console.log(e)
          this.sendLoading = false
				} finally {
          this.sendLoading = false
				}
			},
			submitModifySends(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.sendLoading = true;
						try {
							let data = {
                OrderNo: this.orderDetail.OrderNo,
                ExpressNo: this.sendForm.ExpressNo,
                Express: this.sendForm.ExpressId,
                DetailIdList: this.sendForm.DetailIdList
							}
							let result = await supplierOrderEditExpress(data)
							if (result.IsSuccess) {
                this.modifySendVisible = false
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功!'
								});
							}
						} catch (error) {
							console.log(error)
							this.sendLoading = false;
						} finally {
							this.sendLoading = false;
			        this.getOrderdata()
						}
					} else {
						return false;
					}
				});
      },
			initScreenWidth(){
				let screenWidth = document.body.clientWidth;
				window.onresize = () => {
					return (() => {
						screenWidth = document.body.clientWidth;
					})();
				};
				this.widthUp1366 = screenWidth > 1366
				this.bages.size = this.widthUp1366 ? 9 : 5
			},
			handleChangeTab(tab, event) {
				let index  = tab.index
				this.imgsList = this.orderDetail.ExpressList[index].ProductList
				this.bages.current = 1
				this.bages.rest = (this.imgsList.length - this.bages.size * this.bages.current)
			},
			handleLeft(){
        this.bages.current -= 1
				this.bages.rest = (this.imgsList.length - this.bages.size*this.bages.current)
      },
      handleRight(){
        this.bages.current += 1
				this.bages.rest = this.imgsList.length - this.bages.size*this.bages.current
      },
			setClassName({
				row,
				index
			}) {
				let length = 0
				if (row && row.MixList) {
					length = row.MixList.length
				}
				console.log('row', row, length)
				if (!length) {
					return 'unexpand'
				} else {
					return ''
				}
			},
			// 退款
			refundMoneysChange() {
				if (this.refuseTable[0].RefundCount > this.refuseTable[0].RemainCount) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '最多可退' + this.refuseTable[0].RemainCount + '件!'
					});
					this.refuseTable[0].RefundCount = 0
					return
				}

				if(this.refuseTable[0].RemainMoney == 0){

					if (this.refuseTable[0].IsRefundFreight&&this.refuseTable[0].RefundCount>0) {
						this.refuseMoneys = this.refuseTable[0].RemainMoney + this.refuseTable[0].FreightMoney
					} else {
						this.refuseMoneys = this.refuseTable[0].RemainMoney
					}
					
				}else{

					if (this.refuseTable[0].RefundCount < this.refuseTable[0].RemainCount) {
						this.refuseMoneys = this.refuseTable[0].RefundCount * this.refuseTable[0].UnitPrice
						if (this.refuseMoneys > this.refuseTable[0].RemainMoney) {
							this.refuseMoneys = this.refuseTable[0].RemainMoney
						}
					}

					if (this.refuseTable[0].RefundCount == this.refuseTable[0].RemainCount) {

						if (this.refuseTable[0].IsRefundFreight) {
							this.refuseMoneys = this.refuseTable[0].RemainMoney + this.refuseTable[0].FreightMoney
						} else {
							this.refuseMoneys = this.refuseTable[0].RemainMoney
						}

					}

				}

				this.refuseMoneys = this.refuseMoneys.toFixed(2)


			},

			// 查看组合商品
			async lookCounpGoods(record) {
				try {
					let result = await detailcomboProduct({
						OrderDetailId: record.Id
					})
					this.contactData = result.Result

				} catch (e) {
					console.log(e)
				} finally {
					this.contactVisivble = true
				}

			},
			//获取地址数据
			getDataList() {
				var that = this
				axios.get('https://cdn.dkycn.cn/JSON/gdDistrict-v2.json?v='+new Date().getTime(), {})
					.then(function(response) {
						that.dataList = response.data
					})
					.catch(function(error) {})
			},
			handleAddressChange() {
				this.ruleForm.ProvinceId = this.ruleForm.AddressArr[0]
				this.ruleForm.CityId = this.ruleForm.AddressArr[1]
				this.ruleForm.AreadId = this.ruleForm.AddressArr[2]
				this.dataList.map(item => {
					if (item.id == this.ruleForm.ProvinceId) {
						this.ruleForm.Province = item.name
						item.child.map(items => {
							if (items.id == this.ruleForm.CityId) {
								this.ruleForm.City = items.name
								items.child.map(lastitems => {
									if (lastitems.id == this.ruleForm.AreadId) {
										this.ruleForm.Area = lastitems.name
									}
								})
							}
						})
					}
				})

				this.center = {
					lng: 0,
					lat: 0
				}

			},

			// 确认订单备注
			sureOrdermark(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.loading = true;
						try {
							let data = {
								OrderNo: this.OrderNo,
								SystemRemark: this.orderForm.orderMark
							}
							let result = await orderRemark(data)
							if (result.IsSuccess) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功!'
								});
								this.ordermarkDialogVisible = false;
								this.getOrderdata()
							}
						} catch (error) {
							console.log(error)
						} finally {
							this.loading = false;
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});

			},


			// 获取订单数据
			async getOrderdata() {
				this.detailoading = true
				try {
					let result = await orderlookDetail({
						OrderNo: this.OrderNo
					})
					this.orderDetail = JSON.parse(JSON.stringify(result.Result));
					this.CustomerRemark = this.orderDetail.CustomerRemark.split('\n')
					this.productsData = result.Result.ProductList
					this.RecordList = result.Result.RecordList
					this.productAll = 0;
					this.discoutAll = 0;
					this.moneyAll = 0;
					this.orderAll = 0;
					this.productsData.map(item => {
						this.productAll = Number(this.productAll) + Number(item.ProductPrice * item.ProductCount)
						this.discoutAll = Number(this.discoutAll) + Number(item.ReduceMoney)
						this.moneyAll = Number(this.moneyAll) + Number(item.ProductCashbackMoney)
					})
					this.productAll = this.productAll.toFixed(2)
					this.discoutAll = this.discoutAll.toFixed(2)
					this.moneyAll = this.moneyAll.toFixed(2)
					this.orderAll = (Number(this.productAll) + Number(this.discoutAll) + Number(this.orderDetail.FreightMoney)).toFixed(
						2)
					this.ruleForm.Name = result.Result.ReceiveName
					this.ruleForm.phone = result.Result.ReceivePhone
					this.ruleForm.Province = result.Result.ReceiveProvince
					this.ruleForm.City = result.Result.ReceiveCity
					this.ruleForm.Area = result.Result.ReceiveArea
					this.ruleForm.Address = result.Result.ReceiveAddress
					this.ruleForm.AddressArr = [this.orderDetail.ProvinceId + '', result.Result.CityId + '', result.Result.AreaId +
						''
					]

					this.ChangeFreightMoney = result.Result.ChangeFreightMoney

					this.orderDetail.ExpressList.map((t,i)=>{
						t.name = 'tab' +(i+1) 
						return t
					})
					this.imgsList = this.orderDetail.ExpressList[0].ProductList
					this.bages.rest = (this.imgsList.length - this.bages.size * this.bages.current)

				} catch (e) {
					console.log(e)
				} finally {
					this.detailoading = false
				}

			},
			
			// 加星
			addStar() {
				console.log('添加星星')
			},
			// 改变星星
			async rateChange(record) {
				try {
					let data = {
						OrderNo: this.OrderNo,
						Star: this.orderDetail.Star
					}
					let result = await orderIndexStar(data)
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功!'
						});
					}

				} catch (e) {
					console.log(e)
				} finally {
					// this.getOrderdata()
				}


			},
			// 确认退款
			async sureRefuse() {
				try {
					this.loading = true

					if (!this.refuseTable[0].RefundCount) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '请输入退款数量!'
						});
						return
					}

					let data = {
						OrderNo: this.OrderNo,
						IsVirtualRefund: this.isVirtualRefund,
						OrderDetailId: this.OrderDetailId,
						IsCancelAuth: this.ruleForm.resource,
						RefundCount: this.refuseTable[0].RefundCount,
						PayNo:this.orderDetail.PayNo
					}


					let result = await ordererefundsave(data)

					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功!'
						});
						this.getOrderdata()
						this.returnselfVisivble = false
					}


				} catch (e) {
					console.log(e)
				} finally {
					this.loading = false
				}

			},
			// 退款信息
			async returnData() {
				this.loading = true
				try {
					this.refuseTable = []
					let data = {
						OrderNo: this.OrderNo,
						OrderDetailId: this.OrderDetailId
					}

					let result = await ordererefundInit(data);
					this.refuseTable.push(result.Result)
					this.refuseTable.map(item => {
						item.RefundCount = null
						return item
					})
					//初始化价格
					if (this.refuseTable[0].RefundCount < this.refuseTable[0].RemainCount) {
						this.refuseMoneys = this.refuseTable[0].RefundCount * this.refuseTable[0].UnitPrice
						if (this.refuseMoneys > this.refuseTable[0].RemainMoney) {
							this.refuseMoneys = this.refuseTable[0].RemainMoney
						}
					}

					if (this.refuseTable[0].RefundCount == this.refuseTable[0].RemainCount) {

						if (this.refuseTable[0].IsRefundFreight) {
							this.refuseMoneys = this.refuseTable[0].RemainMoney + this.refuseTable[0].FreightMoney
						} else {
							this.refuseMoneys = this.refuseTable[0].RemainMoney
						}

					}


				} catch (e) {
					console.log(e)
				} finally {
					this.loading = false
				}

			},
			// 主动退款
			refuseMoeny(record,isVirtualRefund) {
				this.OrderDetailId = record.Id
				this.isVirtualRefund= isVirtualRefund

				if (this.orderDetail.OrderType == 2 && (this.orderDetail.OrderState == 2 || this.orderDetail.OrderState == 3)) {
					this.nosendVisivble = true
				} else if (this.orderDetail.OrderType == 2 && (this.orderDetail.OrderState == 4 || this.orderDetail.OrderState == 5)) {
					this.successVisivble = true
				} else if (this.orderDetail.OrderType != 2 && this.orderDetail.OrderState == 3) {
					this.checkVisivble = true;
				} else {
					this.returnselfVisivble = true
				}
				this.returnData();



			},

			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.returnselfVisivble = true;
						this.successVisivble = false;

					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			//关闭收货地址弹窗
			modelClose() {
				this.editAddressModel = false
				// this.ruleForm = {
				// 	OrderNo: '',
				// 	Name: '',
				// 	phone: '',
				// 	province: '',
				// 	City: '',
				// 	Area: '',
				// 	Address: '',
				// 	AddressArr: []
				// }
			},


			async saveAddressinfo(formName) {

				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						try {
							let data = {
								OrderNo: this.OrderNo,
								ReceiveName: this.ruleForm.Name,
								ReceivePhone: this.ruleForm.phone,
								ReceiveProvince: this.ruleForm.Province,
								ReceiveCity: this.ruleForm.City,
								ReceiveArea: this.ruleForm.Area,
								ReceiveAddress: this.ruleForm.Address
							}
							let result = await ordereditReceive(data)
							if (result.IsSuccess) {
								this.getOrderdata()
								this.modelClose()
							}
						} catch (error) {

						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});


			},


			// 修改收货地址
			editAdress() {
				if (this.orderDetail.SendType == 0) {
					this.editAddressModel = true
				} else {
					this.noeditesVisivble = true;
				}

			},

			// 查看优惠明细
			async lookBelifDetails() {
				try {

					let result = await detailcomboDetail({
						OrderNo: this.OrderNo
					})
					this.ordermenyData = result.Result

				} catch (e) {
					console.log(e)
				} finally {
					this.orderdetailsVisivble = true;
				}

			}
		}
	}
</script>

<style lang="less" scoped>
.flexRow{
	display: flex;
	align-items: center;
}
	.orderDetailpage {
		width: 100%;
		padding: 10px;

		.orderInformation {
			width: 100%;

			.mainCon {
				width: 100%;
				margin-bottom: 15px;
				box-sizing: border-box;
				padding: 20px 60px 10px 60px;
				background: #fff;
				.pack-tabs{
					display: flex;
					flex-direction: row;
					.left-content{
						width: 50%;
						font-size: 14px;
						line-height: 28px;
						padding-top: 10px; 
						.imgs-content{
							margin-top: 20px;
							margin-left: 10px;
							width: 425px;
							position: relative;
							display: flex;
							flex-direction: row;
							
							.imgs-list{
								width: 375px;
								margin-left: 25px;
                overflow: hidden;
                
                ul,li{ padding:0;margin:0;list-style:none}
								ul{
									display: flex;
									flex-direction: row;
									transform: translateX(0px);
									transition: all 1s ease;
								}
								.imgs-item{
									margin: 0 5px;
									img{
										cursor: pointer;
										border: 1px solid #ddd;
										border-radius: 5px;
										width: 65px;
										height: 65px;
										object-fit: contain;
									}
									div{
										width: 65px;
										overflow: hidden;
										text-overflow: ellipsis;
										white-space: nowrap;
										text-align: center;
									}
								}
							}
							.icon-left{
								cursor: pointer;
								position: absolute;
								left: -20px;
								font-size: 45px;
								line-height: 65px;
								color: #909399;
							}
							.icon-unleft{
								position: absolute;
								left: -20px;
								font-size: 45px;
								line-height: 65px;
								color: #ddd;
							}
							.icon-right{
								cursor: pointer;
								position: absolute;
								right: -20px;
								font-size: 45px;
								line-height: 65px;
								color: #909399;
							}
							.icon-unright{
								position: absolute;
								right: -20px;
								font-size: 45px;
								line-height: 65px;
								color: #ddd;
							}
						}
					}
					.right-content{
						width: 50%;
					}
				}
				
				.line {
					margin-bottom: 20px;
					font-size: 14px;

					.shipping-address {
						width: 95%;
						overflow: hidden;
						word-wrap: break-word;
						display: -webkit-box;
						-webkit-line-clamp: 7; //只展示5行
						-webkit-box-orient: vertical;
					}

					span {
						line-height: 28px;
					}

					div {
						line-height: 28px;
					}
				}

			}

		}

		.el-alert {
			border-radius: 0;
		}

		.comeback-money {
			// padding-right: 50px;
			width: 100%;
			text-align: right;
			font-size: 14px;

			.allMoney {
				border-top: 1px solid #ddd;
				padding-top: 20px;
			}
		}

		.self-refuse {
			display: inline-block;
			width: 75px;
			height: 30px;
			line-height: 30px;
			text-align: center;
			font-size: 14px;
			color: #F56C6C;
			border: 1px solid #F56C6C;
			cursor: pointer;
			margin-top: 5px;
		}

		input[type=number]::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}

		.number__input {
			border: 1px solid #ccc;
		}

		.sendOrders {
			line-height: 23px;
		}

	}

	.refund-record {
		background-color: #fff;
		margin-top: 10px;
		padding: 20px;
		overflow: hidden;

		.title {
			font-weight: bold;
		}

		.refund-timeline {
			margin-top: 20px;

			::v-deep .el-timeline {
				padding-left: 10px !important;
			}
		}
	}

	.el-alert {
		.el-alert__content {
			width: 100%;
		}

		.el-alert__title {
			word-wrap: break-word;
		}
	}

	.reply-expand {
		::v-deep .el-table th {
			padding: 0 !important;
		}

		// z-index: 999;
		width: 100%;
		background-color: #f4f5f7;
		// margin-top:-1px;
		display: flex;
		flex-direction: row;
		padding: 10px;

		.product-info {
			width: 390px;
			// background-color: #409EFF;
		}

		.product-msg {
			word-wrap: break-word;
			width: 150px;
			padding: 0 9px;
			display: flex;
			align-items: center;
		}

		.product-spec {

			word-wrap: break-word;
			width: 150px;
			padding: 0 9px;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

  }
  
  .old-send-mess{
    line-height: 30px;
    border: 1px solid #E6A23C;
    background-color: #FDF6EC;
    color: #E6A23C;
    padding: 10px;
    margin-bottom:20px;
	}
	
	::v-deep .el-dialog__body{
		padding-top: 10px;
	}
	::v-deep .unexpand .el-table__expand-column .cell {
		display: none;
	}

	::v-deep .unexpand .el-table__expanded-cell {
		display: none;
	}

	::v-deep .el-table__expanded-cell[class*=cell] {
		padding: 0 0 0 50px;
	}

	::v-deep .el-table__expanded-cell {
		padding-left: 50px;
	}

	// ::v-deep .hiddenCheck .el-checkbox__input {
	// 	visibility: hidden;
	// }
</style>
